import React from 'react';
import { Hero, Layout, FullImageContent, Container } from '../../components';
import { graphql } from 'gatsby';
import { HeroHeader, HeroSubHeader } from '../../components/Hero/styles';
import content from './content';
import { PaddingSmall, ContentHeader } from '../../styles';
import { COLOR } from '../../constants';
import FeaturedLogos from '../../components/FeaturedLogos';
import useIsDesktop from '../../lib/isDesktop';
import Fade from 'react-reveal/Fade';

const AboutPage = ({ data, path }: any) => {
  const isDesktop = useIsDesktop();

  return (
    <div>
      <Layout isDesktop={isDesktop} path={path} title={content.title}>
        <Hero
          desktopImage={data.heroImage.childImageSharp.fluid}
          mobileImage={data.heroMobileImage.childImageSharp.fluid}
          leftAligned={false}
          isDesktop={isDesktop}
        >
          <HeroHeader>{content.heroHeader}</HeroHeader>
          <HeroSubHeader>{content.heroSubHeader}</HeroSubHeader>
        </Hero>
        <PaddingSmall backgroundcolor={COLOR.WHITE} />
        <FullImageContent content={content.imageContents.ecosystem} image={data.ecosystemImage.childImageSharp.fluid} />
        <FullImageContent
          content={content.imageContents.different}
          image={data.differentImage.childImageSharp.fluid}
          reversed={true}
        />
        <FullImageContent
          content={content.imageContents.communications}
          image={data.communicationsImage.childImageSharp.fluid}
        />

        <Container paddingVertical={'10px'}>
          <ContentHeader>
            <Fade>ThingCo Directors</Fade>
          </ContentHeader>
        </Container>
        <FullImageContent
          content={content.directors.mike}
          image={data.mikeImage.childImageSharp.fluid}
          reversed={true}
          paddingVertical={'0px'}
        />
        <FullImageContent
          content={content.directors.mikeExperience}
          image={data.mikeExperienceImage.childImageSharp.fluid}
        />
        <FullImageContent content={content.directors.jonny} image={data.jonnyImage.childImageSharp.fluid} />
        <FullImageContent
          content={content.directors.martin}
          image={data.martinImage.childImageSharp.fluid}
          reversed={true}
        />
        <PaddingSmall backgroundcolor={COLOR.WHITE} />
        <FeaturedLogos />
      </Layout>
    </div>
  );
};

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "about/about-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroMobileImage: file(relativePath: { eq: "about/about-hero-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ecosystemImage: file(relativePath: { eq: "about/about-littletheo.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    differentImage: file(relativePath: { eq: "about/about-theo.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    communicationsImage: file(relativePath: { eq: "about/about-satellite.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mikeImage: file(relativePath: { eq: "about/directors-mike.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mikeExperienceImage: file(relativePath: { eq: "about/directors-mike-experience.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jonnyImage: file(relativePath: { eq: "about/directors-jonny.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    martinImage: file(relativePath: { eq: "about/directors-martin.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default AboutPage;
