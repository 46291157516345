import React from 'react';

import { Container } from '../';
import { StaticQuery, graphql } from 'gatsby';
import { FeaturedLogo, FeaturedLogoContainer, FeaturedLogoHeader, FeaturedLogoLink } from './styles';
import Fade from 'react-reveal/Fade';

const FeaturedLogos = ({ data }: { data: any }) => {
  return (
    <>
      <Container>
        <Fade>
          <FeaturedLogoHeader>As featured in…</FeaturedLogoHeader>
        </Fade>
      </Container>
      <Container>
        <FeaturedLogoContainer>
          <Fade>
            <FeaturedLogoLink href="https://fintech.global/insurtech100/">
              <FeaturedLogo fixed={data.insurtech100.childImageSharp.fixed} />
            </FeaturedLogoLink>
            <FeaturedLogoLink href="https://awards.insurancetimes.co.uk/tech-awards-21/">
              <FeaturedLogo fixed={data.insuranceInnovation.childImageSharp.fixed} />
            </FeaturedLogoLink>
            <FeaturedLogoLink href="https://awards.insurancetimes.co.uk/itawards/">
              <FeaturedLogo fixed={data.insuranceTimes.childImageSharp.fixed} />
            </FeaturedLogoLink>
            <FeaturedLogoLink href="https://www.insuranceawards.com/">
              <FeaturedLogo fixed={data.insurancePost.childImageSharp.fixed} />
            </FeaturedLogoLink>
            <FeaturedLogoLink href="https://businesscloud.co.uk/insurtech-50-uks-most-innovative-insurance-technology-creators-for-2021/">
              <FeaturedLogo fixed={data.insurtech50.childImageSharp.fixed} />
            </FeaturedLogoLink>
          </Fade>
        </FeaturedLogoContainer>
      </Container>
    </>
  );
};

export default (props: any) => (
  <StaticQuery
    query={graphql`
      query {
        insurtech100: file(relativePath: { eq: "featuredLogos/insurtech-100.png" }) {
          childImageSharp {
            fixed(width: 92, quality: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        insuranceInnovation: file(relativePath: { eq: "featuredLogos/insurance-innovation.png" }) {
          childImageSharp {
            fixed(width: 121, quality: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        insuranceTimes: file(relativePath: { eq: "featuredLogos/insurance-times.png" }) {
          childImageSharp {
            fixed(width: 182, quality: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        insurancePost: file(relativePath: { eq: "featuredLogos/insurance-post.png" }) {
          childImageSharp {
            fixed(width: 274, quality: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        insurtech50: file(relativePath: { eq: "featuredLogos/insurtech-50.png" }) {
          childImageSharp {
            fixed(width: 183, quality: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => <FeaturedLogos data={data} {...props} />}
  />
);
