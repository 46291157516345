import { IFullImageContent } from '../../components/FullImageContent/index';

interface IAboutPageContent {
  title: string;
  heroHeader: string;
  heroSubHeader: string;
  imageContents: {
    ecosystem: IFullImageContent;
    different: IFullImageContent;
    communications: IFullImageContent;
  };
  directors: {
    mike: IFullImageContent;
    mikeExperience: IFullImageContent;
    jonny: IFullImageContent;
    martin: IFullImageContent;
  };
}

const content: IAboutPageContent = {
  title: 'About ThingCo',
  heroHeader: 'About ThingCo',
  heroSubHeader:
    'ThingCo is an insurtech company, focused on developing next generation telematics built on the latest technology.',
  imageContents: {
    ecosystem: {
      header: 'Supporting b2b, Thinking b2c',
      subheader: 'Set up in January 2018 by Mike Brockman (Founder and ex CEO of Insurethebox).',
      description: `ThingCo has built a suite of revolutionary b2b and b2c products and services to address the weaknesses, including technical, practical and regulatory challenges, in the current market using ThingCo’s deep understanding of insurance and telematics.

      ThingCo also sells directly to the consumer via the brand Theo. Theo is sold separately from insurance, demonstrating that consumers value the technology in its own right. Find out more about <a href="https://drivetheo.com/"> Theo at Drivetheo.com </a>`,
    },
    different: {
      header: 'Why are ThingCo Different?',
      subheader:
        'Different by nature, ThingCo steps ahead of competition by applying next generation technology in ways not previously seen.',
      description: `All ThingCo devices apply AI driven intelligent voice in-car, powered by Alexa technology, to deliver real-time engagement and risk reduction activities, bringing you closer to your customers and stopping accidents before they occur.

      Integrating intelligent voice into Smart telematics FNOL enables you to get to your customers first and validate if an accident has happened without human interaction.`,
    },
    communications: {
      subheader: 'Underpinned by a state-of-the-art communications platform',
      description: `Using AWS IoT, and identified by AWS as of the of the best applications of Serverless and Infrastructure as Code technology; low cost and deployable in any country and to any business size.

      ThingCo not only empowers partners with the technology but, using our extensive experience, assists in extracting the 'asset value' in the technology and applying it in practice.`,
    },
  },
  directors: {
    mike: {
      header: 'Mike Brockman',
      subheader: 'CEO',
      description: `Mike is one of the worlds experts in motor insurance with his experience spanning over 4 decades. His passion has always been to positively change the motor insurance market while using telematics to drive down the ever-rising cost in premiums.

      His passion is to educate everyone on safer driving by using telematics and to reward these driving behaviours making our roads a safer place.`,
    },
    mikeExperience: {
      subheader: 'Previous Experience',
      description: `Mike was previously the CEO, and founder, of Insure the Box which launched in 2009, quickly becoming a pioneer of telematics based motor insurance. Since then Insure the Box has collected more than 3 billion miles of driving data including associated claims. Now owned by the MS&AD Group, Insure the Box has sold more than 1 million polices. 

      Mike founded his first company EMB in 1993 which went on to become the largest firm of non-life actuarial consultants in the UK. EMB employed more the 300 people in 11 countries. EMB was highly regarded and was awarded the Queen's Award for Enterprise twice, first in 2003 for Innovation and then in 2007 for International Trade. The company was sold to Towers Watson in 2011.`,
    },
    jonny: {
      header: 'Jon Valentine',
      subheader: 'CTO',
      description: `Jon also was previously an integral component of Insure the Box from the beginning when it was a start up, initially running the web communications team and focusing on the architecture and implementation of the brands customer facing system.

      Jon then became Innovation lead, helping the company discover new technology to push the business forward, as well as find new ways to embrace technology in it day-to-day running. His anti-fraud tool, CARBS, won awards including Technology Initiative of the Year at the Fraud Awards and Technology Product of the year at the Insurance Technology Awards.`,
    },
    martin: {
      header: 'Martin Williams',
      subheader: 'Chief Operating Officer and Board Director',
      description: `Martin is an experienced and proven senior Executive in the General Insurance and Telematic sector. Resourceful and highly knowledgeable in Strategic Development, Marketing and Sales/Partner Management across multi-channel distribution. Martin is one of the motor insurance market's most respected figures and was appointed Chief Operating Officer and Board Director for ThingCo in August 2020. Martin joins ThingCo from Octo Telematics, where he held the position of Managing Director and VP Revenue and Sales for the past 7 years. Having the perfect mix of Global Telematics experience, deep industry knowledge and strong strategic thinking, Martin will help lead ThingCo to the next level and is passionate to change the game in motor insurance telematics through ThingCo's future proof technology.`,
    },
  },
};

export default content;
