import styled from 'styled-components';

import { COLOR } from '../../constants';
import Img from 'gatsby-image';
import { FlexCenter, ContentHeader, mobileMixin } from '../../styles';
export const backgroundcolor = COLOR.DARKBLUE;

export const FeaturedLogoLink = styled.a``;

export const FeaturedLogoHeader = styled(ContentHeader)``;

export const FeaturedLogoContainer = styled(FlexCenter)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
  margin-bottom: 100px;

  ${mobileMixin(`
    flex-direction: column;
  `)};
`;

export const FeaturedLogo = styled(Img)``;
